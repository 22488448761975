<template>
  <div class="h padding-10" style="height: 480px; margin-top: 20px;" v-if="dashboard">
    <div class="v" style="width: 500px">
      <div class="h c sb" style="margin-bottom: 10px;">
        <b>各省市库存/金额情况</b>
        <el-input v-model.trim="filter.name" :maxlength="10" placeholder="输入城市名称过滤" style="width: 180px;">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <el-table :data="filteredCityDistribution" border size="small" height="200px">
        <el-table-column prop="provinceName" label="省" min-width="120" />
        <el-table-column prop="cityName" label="市" min-width="120" />
        <el-table-column prop="stock" label="库存（件）" min-width="100" align="right" />
        <el-table-column label="金额（元）" min-width="120" align="right">
          <template slot-scope="scope">{{$price(scope.row.money * 100)}}</template>
        </el-table-column>
      </el-table>
    </div>
    <div ref="brandDistribution" class="flex"></div>
  </div>

  <div class="app-container v" v-else>
    <div class="head-container">
      <quick-select url="api/brand" v-model="query.brandIds" filterable clearable placeholder="请选择品牌" style="width: 250px;" multiple />
      <tree-picker v-model="query.seriesId" :params="{brandIds:query.brandIds}" url="api/series/tree" clearable placeholder="请选择系列" style="width: 150px;" v-if="query.brandIds" />
      <tree-picker v-model="query.categoryId" url="api/category/tree" clearable placeholder="请选择分类" @change="toQuery" class="filter-item" style="width: 150px;" />
      <el-radio-group v-model="mode" class="filter-item" @change="drawDistribution">
        <el-radio-button label="stock">库存</el-radio-button>
        <el-radio-button label="money">金额</el-radio-button>
      </el-radio-group>
      <el-button class="filter-item" type="success" icon="el-icon-search" :loading="loading" @click="toQuery">统计</el-button>
    </div>
    <div class="flex scroll-able scroll-container-main" v-loading="loading">
      <div class="bc-l h" style="height: 720px;">
        <div ref="regionDistribution" style="width: 360px;"></div>
        <!-- <div class="padding-10" style="width: 360px;">
          <el-table border :data="store.regionDistribution" size="mini" height="700">
            <el-table-column prop="regionName" label="区域" min-width="100" />
            <el-table-column prop="stock" label="库存（件）" min-width="100" align="right" />
            <el-table-column label="金额（元）" min-width="120" align="right">
              <template slot-scope="scope">{{$price(scope.row.money * 100)}}</template>
            </el-table-column>
          </el-table>
        </div>-->
        <div ref="cityDistribution" class="flex"></div>
      </div>

      <div class="h bc-l padding-10" style="height: 480px; margin-top: 20px;">
        <div class="v" style="width: 500px">
          <div class="h c sb" style="margin-bottom: 10px;">
            <b>各省市库存/金额情况</b>
            <el-input v-model.trim="filter.name" :maxlength="10" placeholder="输入城市名称过滤" style="width: 180px;">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <el-table :data="filteredCityDistribution" border size="small" height="200px">
            <el-table-column prop="provinceName" label="省" min-width="120" />
            <el-table-column prop="cityName" label="市" min-width="120" />
            <el-table-column prop="stock" label="库存（件）" min-width="100" align="right" />
            <el-table-column label="金额（元）" min-width="120" align="right">
              <template slot-scope="scope">{{$price(scope.row.money * 100)}}</template>
            </el-table-column>
          </el-table>
        </div>
        <div ref="brandDistribution" class="flex"></div>
      </div>
    </div>

    <el-dialog :visible.sync="distributor.show" title="经销商存量对比" append-to-body :close-on-click-modal="false" width="960px">
      <div class="h bc-l">
        <div ref="distributorDistribution" class="flex"></div>
        <div style="width: 360px">
          <el-table :data="distributor.store" height="360px">
            <el-table-column prop="distributorName" label="经销商" min-width="140" />
            <el-table-column prop="stock" label="库存（件）" min-width="100" align="right" />
            <el-table-column prop="money" label="金额（万元）" min-width="100" align="right" />
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    dashboard: Boolean,
  },
  data() {
    return {
      loading: false,
      mode: "stock",
      query: {
        brandIds: null,
        seriesId: null,
        categoryId: null,
      },
      filter: {
        name: "",
      },
      store: {
        regionDistribution: [],
        cityDistribution: [],
      },
      distributor: {
        show: false,
        store: [
          {
            distributorName: "经销商1",
            stock: Math.round(Math.random() * 300 + 40),
            money: Math.round(Math.random() * 450000 + 20000) / 10000,
          },
          {
            distributorName: "经销商2",
            stock: Math.round(Math.random() * 300 + 40),
            money: Math.round(Math.random() * 450000 + 20000) / 10000,
          },
          {
            distributorName: "经销商3",
            stock: Math.round(Math.random() * 300 + 40),
            money: Math.round(Math.random() * 450000 + 20000) / 10000,
          },
          {
            distributorName: "经销商4",
            stock: Math.round(Math.random() * 300 + 40),
            money: Math.round(Math.random() * 450000 + 20000) / 10000,
          },
          {
            distributorName: "经销商5",
            stock: Math.round(Math.random() * 300 + 40),
            money: Math.round(Math.random() * 450000 + 20000) / 10000,
          },
          {
            distributorName: "经销商6",
            stock: Math.round(Math.random() * 300 + 40),
            money: Math.round(Math.random() * 450000 + 20000) / 10000,
          },
          {
            distributorName: "经销商7",
            stock: Math.round(Math.random() * 300 + 40),
            money: Math.round(Math.random() * 450000 + 20000) / 10000,
          },
          {
            distributorName: "经销商8",
            stock: Math.round(Math.random() * 300 + 40),
            money: Math.round(Math.random() * 450000 + 20000) / 10000,
          },
        ],
      },
      chart: {
        regionDistribution: null,
        cityDistribution: null,
        brandDistribution: null,
        distributorDistribution: null,
      },
    };
  },
  computed: {
    filteredCityDistribution() {
      if (this.filter.name) {
        return this.store.cityDistribution.filter((o) => {
          return o.cityName.indexOf(this.filter.name) >= 0;
        });
      } else {
        return this.store.cityDistribution;
      }
    },
  },
  methods: {
    toQuery() {
      this.drawDistribution();
      this.drawBrandDistributionChart();
    },
    drawDistribution() {
      if (!this.dashboard) this.drawRegionDistributionChart();
      this.drawCityDistributionChart();
    },
    drawRegionDistributionChart() {
      request
        .get("@host:analysis;api/analy/stock/area", { params: this.query })
        .then((res) => {
          this.store.regionDistribution = (res || []).map((subs) => {
            return {
              regionName: subs[1],
              stock: subs[2],
              money: subs[3] / 100,
            };
          });
          if (!this.chart.regionDistribution) {
            this.chart.regionDistribution = echarts.init(
              this.$refs.regionDistribution,
              "light"
            );
          }
          this.chart.regionDistribution.clear();
          this.chart.regionDistribution.setOption({
            title: {
              text: this.mode === "stock" ? "区域库存情况" : "区域金额情况",
              textStyle: {
                fontSize: 14,
                fontFamily: "PingFang SC",
              },
              top: 10,
              left: "center",
            },
            tooltip: {
              trigger: "item",
              formatter: "{b}<br />{c} ({d}%)",
            },
            legend: {
              padding: 0,
              itemWidth: 15,
              itemHeight: 10,
              show: true,
              bottom: 10,
              textStyle: {
                fontSize: 12,
                fontFamily: "PingFang SC",
              },
            },
            series: {
              type: "pie",
              radius: [50, 150],
              center: ["50%", "48%"],
              // roseType: "radius",
              data: this.store.regionDistribution.map((o) => {
                return { name: o.regionName, value: o[this.mode] };
              }),
              label: { show: false },
            },
          });
        });
    },
    drawCityDistributionChart() {
      request
        .get("@host:analysis;api/analy/stock/city", { params: this.query })
        .then((res) => {
          this.store.cityDistribution = (res || []).map((subs) => {
            return {
              provinceName: subs[1],
              cityName: subs[3],
              stock: subs[4],
              money: subs[5] / 100,
              lng: subs[6],
              lat: subs[7],
            };
          });
          let maxv = 0;
          let data = this.store.cityDistribution.map((o) => {
            if (o[this.mode] > maxv) maxv = o[this.mode];
            return {
              name: o.cityName,
              value: [o.lng, o.lat, o[this.mode]],
            };
          });
          let maxSize = 50,
            ratio = Math.ceil(maxv / maxSize),
            mode = this.mode,
            _price = this.$price;
          if (!this.chart.cityDistribution) {
            this.chart.cityDistribution = echarts.init(
              this.$refs.cityDistribution,
              "light"
            );
            // this.chart.cityDistribution.on(
            //   "click",
            //   "series.scatter",
            //   this.handleDistributorDistribution
            // );
          }
          this.chart.cityDistribution.clear();
          this.chart.cityDistribution.setOption(
            {
              title: {
                text: this.mode === "stock" ? "库存分布情况" : "金额分布情况",
                textStyle: {
                  fontSize: 14,
                  fontFamily: "PingFang SC",
                },
                top: 10,
                left: "center",
              },
              tooltip: {
                trigger: "item",
                formatter: function (o) {
                  if (mode === "stock") {
                    return o.name + ": " + o.value[2] + "件";
                  } else {
                    return o.name + ": " + _price(o.value[2] * 100);
                  }
                },
              },
              bmap: {
                center: [104.114129, 37.550339],
                zoom: 5,
                roam: true,
                mapStyle: {
                  styleJson: [
                    {
                      featureType: "water",
                      elementType: "all",
                      stylers: {
                        color: "#d1d1d1",
                      },
                    },
                    {
                      featureType: "land",
                      elementType: "all",
                      stylers: {
                        color: "#f3f3f3",
                      },
                    },
                    {
                      featureType: "railway",
                      elementType: "all",
                      stylers: {
                        visibility: "off",
                      },
                    },
                    {
                      featureType: "highway",
                      elementType: "all",
                      stylers: {
                        color: "#fdfdfd",
                      },
                    },
                    {
                      featureType: "highway",
                      elementType: "labels",
                      stylers: {
                        visibility: "off",
                      },
                    },
                    {
                      featureType: "arterial",
                      elementType: "geometry",
                      stylers: {
                        color: "#fefefe",
                      },
                    },
                    {
                      featureType: "arterial",
                      elementType: "geometry.fill",
                      stylers: {
                        color: "#fefefe",
                      },
                    },
                    {
                      featureType: "poi",
                      elementType: "all",
                      stylers: {
                        visibility: "off",
                      },
                    },
                    {
                      featureType: "green",
                      elementType: "all",
                      stylers: {
                        visibility: "off",
                      },
                    },
                    {
                      featureType: "subway",
                      elementType: "all",
                      stylers: {
                        visibility: "off",
                      },
                    },
                    {
                      featureType: "manmade",
                      elementType: "all",
                      stylers: {
                        color: "#d1d1d1",
                      },
                    },
                    {
                      featureType: "local",
                      elementType: "all",
                      stylers: {
                        color: "#d1d1d1",
                      },
                    },
                    {
                      featureType: "arterial",
                      elementType: "labels",
                      stylers: {
                        visibility: "off",
                      },
                    },
                    {
                      featureType: "boundary",
                      elementType: "all",
                      stylers: {
                        color: "#fefefe",
                      },
                    },
                    {
                      featureType: "building",
                      elementType: "all",
                      stylers: {
                        color: "#d1d1d1",
                      },
                    },
                    {
                      featureType: "label",
                      elementType: "labels.text.fill",
                      stylers: {
                        color: "#999999",
                      },
                    },
                  ],
                },
              },
              series: {
                type: "scatter",
                coordinateSystem: "bmap",
                data: data,
                symbolSize: function (val) {
                  let v = val[2] / ratio;
                  if (v <= 0) return 0;
                  if (v < 10) v = 10;
                  else if (v > maxSize) v = maxSize;
                  return v;
                },
              },
            },
            {
              notMerge: true,
            }
          );
          let bmap = this.chart.cityDistribution
            .getModel()
            .getComponent("bmap")
            .getBMap();
          bmap.disableDoubleClickZoom();
          bmap.disableScrollWheelZoom();
          bmap.setMinZoom(5);
          bmap.setMaxZoom(5);
        });
    },
    pieLabelFormatter(o) {
      return `${o.name}\n${this.$price(o.value * 100)} (${o.percent}%)`;
    },
    drawBrandDistributionChart() {
      request
        .get("@host:analysis;api/analy/stock/series", { params: this.query })
        .then((res) => {
          let d1 = [],
            d2 = [];
          for (let subs of res || []) {
            d1.push({
              name: subs[1],
              value: subs[2],
            });
            d2.push({
              name: subs[1],
              value: subs[3] / 100,
            });
          }
          if (!this.chart.brandDistribution) {
            this.chart.brandDistribution = echarts.init(
              this.$refs.brandDistribution,
              "light"
            );
          }
          this.chart.brandDistribution.clear();
          this.chart.brandDistribution.setOption({
            title: [
              {
                text: "各品牌存量占比",
                textStyle: {
                  fontSize: 14,
                  fontFamily: "PingFang SC",
                },
                top: 4,
                left: 20,
              },
              {
                text: "库存占比（件）",
                textStyle: {
                  fontSize: 14,
                  fontFamily: "PingFang SC",
                  fontWeight: "normal",
                },
                textAlign: "center",
                bottom: 5,
                left: "25%",
              },
              {
                text: "金额占比（元）",
                textStyle: {
                  fontSize: 14,
                  fontFamily: "PingFang SC",
                  fontWeight: "normal",
                },
                textAlign: "center",
                bottom: 5,
                left: "75%",
              },
            ],
            legend: {
              padding: 0,
              itemWidth: 15,
              itemHeight: 10,
              width: "70%",
              show: true,
              top: 10,
              left: "center",
              textStyle: {
                fontSize: 12,
                fontFamily: "PingFang SC",
              },
            },
            series: [
              {
                type: "pie",
                radius: 120,
                center: ["25%", "48%"],
                label: {
                  show: true,
                  lineHeight: 16,
                  formatter: "{b}\n{c} ({d}%)",
                  fontSize: 12,
                  fontFamily: "PingFang SC",
                  color: "#606266",
                },
                labelLine: {
                  show: true,
                  smooth: true,
                  lineStyle: {
                    color: "#606266",
                    opacity: 0.5,
                  },
                },
                data: d1,
              },
              {
                type: "pie",
                radius: 120,
                center: ["75%", "48%"],
                label: {
                  show: true,
                  formatter: this.pieLabelFormatter,
                  fontSize: 12,
                  lineHeight: 16,
                  fontFamily: "PingFang SC",
                  color: "#606266",
                },
                labelLine: {
                  show: true,
                  smooth: true,
                  lineStyle: {
                    color: "#606266",
                    opacity: 0.5,
                  },
                },
                data: d2,
              },
            ],
          });
        });
    },
    drawDistributorDistributionChart() {
      if (!this.chart.distributorDistribution) {
        this.chart.distributorDistribution = echarts.init(
          this.$refs.distributorDistribution,
          "light"
        );
      }
      this.chart.distributorDistribution.clear();
      let cs = [],
        d1 = [],
        d2 = [];
      this.distributor.store.forEach((o) => {
        cs.push(o.distributorName);
        d1.push(o.stock);
        d2.push(o.money);
      });
      this.chart.distributorDistribution.setOption({
        grid: {
          top: 50,
          right: 60,
          bottom: 60,
          left: 60,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          padding: 0,
          itemWidth: 15,
          itemHeight: 10,
          show: true,
          bottom: 10,
          textStyle: {
            fontSize: 12,
            fontFamily: "PingFang SC",
          },
        },
        xAxis: {
          type: "category",
          data: cs,
        },
        yAxis: [
          {
            type: "value",
            name: "库存（件）",
          },
          {
            type: "value",
            name: "金额（万元）",
          },
        ],
        series: [
          {
            name: "库存",
            type: "bar",
            data: d1,
          },
          {
            name: "金额",
            type: "bar",
            yAxisIndex: 1,
            data: d2,
          },
        ],
      });
    },
    handleDistributorDistribution(e) {
      this.distributor.show = true;
      setTimeout((_) => {
        this.drawDistributorDistributionChart();
      }, 1000);
    },
  },
  mounted() {
    setTimeout((_) => {
      this.toQuery();
    }, 1000);
  },
};
</script>